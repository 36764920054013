import img1 from './img/01.jpg';
import img2 from './img/02.jpg';
import img3 from './img/03.jpg';
import img4 from './img/04.jpg';
import img5 from './img/05.jpg';
import img6 from './img/06.jpg';
import img7 from './img/07.jpg';
import img8 from './img/08.jpg';
import img9 from './img/09.jpg';
import img10 from './img/10.jpg';
import img11 from './img/11.jpg';
import img12 from './img/12.jpg';
import img13 from './img/13.jpg';
import img14 from './img/14.jpg';
import img15 from './img/15.jpg';
import img16 from './img/16.jpg';
import img17 from './img/17.jpg';
import img18 from './img/18.jpg';
import img19 from './img/19.jpg';
import img20 from './img/20.jpg';
import img21 from './img/21.jpg';
import img22 from './img/22.jpg';
import img23 from './img/23.jpg';
import img24 from './img/24.jpg';

import './App.css';
import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import useWindowDimensions from './hook';

const App = () => {
  var settings = {
    dots: false,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3500,
    arrows: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
       breakpoint: 1440,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          autoplaySpeed: 1750,
        } 
      },
      {
        breakpoint: 950,
         settings: {
           slidesToShow: 1,
           slidesToScroll: 1,
           autoplaySpeed: 1250
         } 
       }
    ]
  };
  return (
    <div className="full">
      <Slider {...settings}>
        <div className="carItem">
          <img src={img1}/>
        </div>
        <div className="carItem">
          <img src={img2}/>
        </div>
        <div className="carItem">
          <img src={img3}/>
        </div>
        <div className="carItem">
          <img src={img4}/>
        </div>
        <div className="carItem">
          <img src={img5}/>
        </div>
        <div className="carItem">
          <img src={img6}/>
        </div>
        <div className="carItem">
          <img src={img7}/>
        </div>
        <div className="carItem">
          <img src={img8}/>
        </div>
        <div className="carItem">
          <img src={img9}/>
        </div>
        <div className="carItem">
          <img src={img10}/>
        </div>
        <div className="carItem">
          <img src={img11}/>
        </div>
        <div className="carItem">
          <img src={img12}/>
        </div>
        <div className="carItem">
          <img src={img13}/>
        </div>
        <div className="carItem">
          <img src={img14}/>
        </div>
        <div className="carItem">
          <img src={img15}/>
        </div>
        <div className="carItem">
          <img src={img16}/>
        </div>
        <div className="carItem">
          <img src={img17}/>
        </div>
        <div className="carItem">
          <img src={img18}/>
        </div>
        <div className="carItem">
          <img src={img19}/>
        </div>
        <div className="carItem">
          <img src={img20}/>
        </div>
        <div className="carItem">
          <img src={img21}/>
        </div>
        <div className="carItem">
          <img src={img22}/>
        </div>
        <div className="carItem">
          <img src={img23}/>
        </div>
        <div className="carItem">
          <img src={img24}/>
        </div>
        
      </Slider>
      <div className="footer">
        <p>lernolingo@gmail.com</p>
      </div>
    </div>
  );
};

export default App;
